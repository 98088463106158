

$loader-color: #dd6a58;
$border-color: #DADADA;
$accent-color: #dd6a58;

.ember-light-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  font-family: 'Open Sans', sans-serif;

  .multi-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  tfoot .lt-column {
    border-top: 1px solid $border-color;
  }

  thead .lt-column {
    border-bottom: 1px solid $border-color;
  }

  thead th,
  tfoot th {
    &.is-dragging {
      opacity: 0.75;
      background: #eee;
    }

    &.is-drag-target {
      &.drag-right {
        border-right: 1px dotted $border-color;
      }

      &.drag-left {
        border-left: 1px dotted $border-color;
      }
    }
  }

  .lt-column {
    font-weight: 200;
    font-size: 12px;
    padding: 10px;

    .lt-sort-icon {
      width: 15px;
    }

    &.lt-group-column {
      border: none !important;
      padding-bottom: 10px;
    }

    .lt-column-resizer {
      border-right: 1px dashed #ccc;
      border-left: 1px dashed #ccc;
    }
  }

  .lt-row {
    height: 50px;

    &.is-selected {
      background-color: #DEDEDE;
    }

    &:not(.is-selected):hover {
      background-color: #F5F4F4;
    }

    &:last-of-type {
      td {
        border-bottom-width: 0;
      }
    }

    &.lt-expanded-row, &.lt-no-data {
      &:hover {
        background-color: transparent;
      }
      td {
        padding: 15px;
      }
    }

    &.colored-row {
      color: white;

      &:hover {
        opacity: 0.8;
      }
    }

    td {
      border-color: $border-color;
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
      font-size: 13px;
      padding: 0 10px;
    }
  }
}

tfoot {
  tr > td {
    border-top: 1px solid #DADADA;
    padding: 10px 10px 0 10px;
    font-size: 13px;
  }

  form {
    display: flex;
    justify-content: space-between;
  }

  .pagination {
    margin: 5px 0;
    li > a {
      color: darken($accent-color, 5%);
    }

    li > a:hover,
    li > a:focus,
    li.active > a:hover,
    li.active > a:focus,
    li.active > a {
      color: white;
      background: darken($accent-color, 10%);
      border-color: darken($accent-color, 15%);

    }
  }
}

.spinner {
  margin: 15px auto;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: $loader-color;
  height: 100%;
  width: 4px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}